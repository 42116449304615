
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'IconManager'
})
export default class extends Vue {
  list: Array<string> = []

  mounted() {
    const modulesFiles = require.context("@/icons/svg",false,/\.svg$/)
    modulesFiles.keys().forEach(rd =>{
      this.list.push(rd.substring(rd.indexOf("/") + 1, rd.lastIndexOf(".")))
    })
  }

  copyText(text:string){
    document.addEventListener('copy',(e:any) =>{
      e.clipboardData.setData('text/plain',text);
      e.preventDefault();
    });
    document.execCommand('copy');
    this.$message.success('复制成功');
  }
}
